<template>
  <ion-content class="">
    <div class="flex items-center h-full">
      <div class="flex-1">
        <div class="ion-text-center flex justify-center mb-5">
          <router-link :to="{ name: $routeNames.HOME }">
            <ion-img src="/img/logo.svg" class="logo" />
          </router-link>
        </div>

          <div class="ion-padding text-center">
            <ion-text>{{ $t('verificationsuccessful',{motoinsideappname: $t('motoinsideappname')}) }}</ion-text>
          </div>

          <div class="ion-padding">
            <ion-button v-if="!user" color="moto" type="button" expand="full" @click="$router.replace({ name: $routeNames.LOGIN })">
              {{ $t('login') }}
            </ion-button>
            <ion-button type="button" expand="full" @click="$router.replace({ name: $routeNames.HOME })">
              {{ $t('backtoapp') }}
            </ion-button>
          </div>
      </div>
    </div>
  </ion-content>
</template>
<script>
  import { mapGetters } from "vuex";
  import { CURRENT_USER_GETTER } from "@/store/store-getters";

  export default {
    computed: {
      ...mapGetters({user: CURRENT_USER_GETTER}),
    }
  }
</script>